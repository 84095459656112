import React from 'react';

const Settings = () => (
  <div>
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">设置</h2>
    <p>这里是设置页面的内容。</p>
  </div>
);

export default Settings;
