import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken, refreshToken } from '../services/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ArticleManagement = () => {
  const [articles, setArticles] = useState([]);
  const [editingArticle, setEditingArticle] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchArticles();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const apiCall = async (method, url, data = null) => {
    let token;
    try {
      token = await getToken();
      const response = await axios({
        method,
        url: `${API_BASE_URL}${url}`,
        data,
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
        token = await getToken();
        const response = await axios({
          method,
          url: `${API_BASE_URL}${url}`,
          data,
          headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
      }
      throw error;
    }
  };

  const fetchArticles = async () => {
    try {
      const data = await apiCall('get', '/posts');
      setArticles(data);
    } catch (error) {
      console.error('获取文章列表失败:', error);
    }
  };

  const confirmDelete = (article) => {
    setArticleToDelete(article);
    setShowDeleteModal(true);
  };

  const cancelDelete = () => {
    setArticleToDelete(null);
    setShowDeleteModal(false);
  };

  const deleteArticle = async () => {
    if (!articleToDelete) return;

    try {
      await apiCall('delete', `/posts/${articleToDelete.id}`);
      setShowDeleteModal(false);
      setSuccessMessage(`成功删除文章 "${articleToDelete.title}"`);
      setArticleToDelete(null);
      fetchArticles();
    } catch (error) {
      console.error('删除文章失败:', error);
    }
  };

  const editArticle = (article) => {
    setEditingArticle(article);
    setTitle(article.title);
    setContent(article.content);
  };

  const updateArticle = async () => {
    try {
      await apiCall('put', `/posts/${editingArticle.id}`, { title, content });
      setEditingArticle(null);
      setTitle('');
      setContent('');
      setSuccessMessage(`成功更新文章 "${title}"`);
      fetchArticles();
    } catch (error) {
      console.error('更新文章失败:', error);
    }
  };

  const handleEditorChange = ({ html, text }) => {
    setContent(text);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('zh-CN', options);
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">文章管理</h2>
      
      {/* 成功消息 */}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}

      {/* 编辑表单 */}
      {editingArticle && (
        <div className="mb-4">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 mb-2 border rounded"
            placeholder="文章标题"
          />
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full p-2 mb-2 border rounded"
            placeholder="文章内容"
            rows="10"
          />
          <button
            onClick={updateArticle}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            更新文章
          </button>
        </div>
      )}

      {/* 文章列表 */}
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">标题</th>
            <th className="py-2 px-4 border-b">创建日期</th>
            <th className="py-2 px-4 border-b">操作</th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article) => (
            <tr key={article.id}>
              <td className="py-2 px-4 border-b">{article.title}</td>
              <td className="py-2 px-4 border-b">{formatDate(article.createdAt)}</td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => editArticle(article)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >
                  修改
                </button>
                <button
                  onClick={() => confirmDelete(article)}
                  className="text-red-500 hover:text-red-700"
                >
                  删除
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* 删除确认弹出框 */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">确认删除</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  您确定要删除文章 "{articleToDelete?.title}" 吗？此操作不可逆。
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-24 mr-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={deleteArticle}
                >
                  删除
                </button>
                <button
                  id="cancel-btn"
                  className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-24 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={cancelDelete}
                >
                  取消
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleManagement;
