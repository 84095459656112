import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getToken = async () => {
  let token = localStorage.getItem('access_token');
  if (!token) {
    token = await refreshToken();
  }
  return token;
};

export const refreshToken = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/token`, {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET
    });
    const { access_token } = response.data;
    localStorage.setItem('access_token', access_token);
    return access_token;
  } catch (error) {
    console.error('获取 token 失败:', error);
    throw error;
  }
};
