import React from 'react';

const Dashboard = () => (
  <div>
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">欢迎回来，管理员</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">总用户数</h3>
        <p className="text-3xl font-bold text-yellow-500">1,234</p>
      </div>
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">本月收入</h3>
        <p className="text-3xl font-bold text-yellow-500">¥56,789</p>
      </div>
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">文章数量</h3>
        <p className="text-3xl font-bold text-yellow-500">42</p>
      </div>
    </div>
  </div>
);

export default Dashboard;
