import React from 'react';

const Footer = () => (
  <footer className="bg-gray-800 py-4">
    <div className="container mx-auto text-center text-sm text-gray-300">
      © 2024 管理系统. 保留所有权利。
    </div>
  </footer>
);

export default Footer;
