import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { FiMenu, FiSearch, FiBell, FiUser } from 'react-icons/fi';
import Dashboard from './pages/Dashboard';
import ArticleManagement from './pages/ArticleManagement';
import Settings from './pages/Settings';
import Footer from './pages/Footer';

function App() {
  return (
    <Router>
      <div className="flex flex-col h-screen bg-gray-100">
        <div className="flex flex-1 overflow-hidden">
          {/* Sidebar */}
          <div className="w-64 bg-white shadow-md">
            <div className="p-4 bg-yellow-500">
              <h1 className="text-white text-2xl font-bold">管理系统</h1>
            </div>
            <nav className="mt-4">
              <Link to="/" className="block py-2 px-4 text-gray-700 hover:bg-yellow-100">仪表盘</Link>
              <Link to="/articles" className="block py-2 px-4 text-gray-700 hover:bg-yellow-100">文章管理</Link>
              <Link to="/settings" className="block py-2 px-4 text-gray-700 hover:bg-yellow-100">设置</Link>
            </nav>
          </div>

          {/* Main content */}
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Header */}
            <header className="bg-white shadow-sm">
              <div className="flex items-center justify-between px-4 py-3">
                <button className="text-gray-500 focus:outline-none focus:text-gray-700">
                  <FiMenu size={24} />
                </button>
                <div className="flex items-center">
                  <div className="relative">
                    <input
                      className="bg-gray-200 text-gray-700 rounded-full py-2 px-4 pl-10 focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="搜索..."
                    />
                    <div className="absolute left-3 top-2 text-gray-500">
                      <FiSearch size={20} />
                    </div>
                  </div>
                  <button className="ml-4 text-gray-500 focus:outline-none focus:text-gray-700">
                    <FiBell size={24} />
                  </button>
                  <button className="ml-4 text-gray-500 focus:outline-none focus:text-gray-700">
                    <FiUser size={24} />
                  </button>
                </div>
              </div>
            </header>

            {/* Page content */}
            <main className="flex-1 p-6 overflow-auto">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/articles" element={<ArticleManagement />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </main>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
